import { useEffect, useState } from 'react'
import { globalHistory } from '@gatsbyjs/reach-router'

import {
  getCampaignData,
  getExistingCampaign,
  setExistingCampaign
} from './analytics.helpers'

export interface AnalyticsCampaign {
  source?: string
  medium?: string
  name?: string
  content?: string
  term?: string
  id?: string
  gclid?: string
}

export const useAnalytics = () => {
  const [campaign, setCampaign] = useState<AnalyticsCampaign>(
    getExistingCampaign()
  )

  const [entryPoint, setEntryPoint] = useState<string>()

  const track = (event: string, properties?: Record<string, any>) =>
    window.analytics.track(event, properties, {
      context: { campaign, entry_point: entryPoint }
    })

  const identify = (traits?: Record<string, any>) => {
    window.analytics.identify(traits, {
      context: { campaign, entry_point: entryPoint }
    })
  }

  const page = (pageName: string, properties?: Record<string, any>) =>
    window.analytics.page(pageName, properties, {
      context: { campaign, entry_point: entryPoint }
    })

  const setCurrentCampaign = (search: string) => {
    if (!search) {
      return
    }
    const newCampaign = getCampaignData(search)
    if (newCampaign) {
      setExistingCampaign(newCampaign)
      setCampaign(newCampaign)
    }
  }

  const updateEntryPoint = () => {
    setEntryPoint(window?.location?.pathname)
  }

  useEffect(() => {
    if (window.location.search) {
      setCurrentCampaign(window.location.search)
    }

    if (window.location.pathname) {
      updateEntryPoint()
    }

    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH' || action === 'REPLACE' || action === 'POP') {
        if (window.location.search) {
          setCurrentCampaign(window.location.search)
        }
      }
    })
  }, [])

  return { campaign, track, identify, page, entryPoint }
}
