const INTERESTS_SET_VAR: string =
  process.env.GATSBY_EVENT_INTERESTS_SET ?? 'interests_set'

export type AnalyticsEventType =
  (typeof AnalyticsEvent)[keyof typeof AnalyticsEvent]


export enum AnalyticsEvent {
  CONTACT_US_SUBMIT = 'contact_us_submit',
  CONTACT_US_SUBMIT_2 = 'contact_us_submit_2',
  INTERESTS_SET = INTERESTS_SET_VAR,
  NEWSLETTER_SUBMIT = 'newsletter_signup',
  REFERRAL_PROGRAM_ENROLLMENT = 'referral_program_enrollment',
  CONTACT_FORM_OPENED = 'contact_form_opened',
  CONTACT_FORM_CLOSED = 'contact_form_closed',
  USER_JOINED_CTA_COHORT = 'user_joined_cta_cohort',
  USER_LEFT_CTA_COHORT = 'user_left_cta_cohort'
}
